import React from "react";
import {useKennaData} from "lang/kennametal";
import Kennametal from "sections/Kennametal/Kennametal";

const KennametalPage = () => {
  const data = useKennaData();
  return <Kennametal data={data}/>;
};

export default KennametalPage;
