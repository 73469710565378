import {useKennaPhotos} from "shared/Hooks/kennametal/useKennaPhotos";
import React from "react";
import KennaLogo from "assets/svg/kennametal-logo.svg";
import RotarySell from "assets/image/kennametal/inventory.png";

export const useKennaData = () => {
  const photos = useKennaPhotos();
  return {
    metaInfo: {
      title: "Kennametal",
      ogDescription:
        "Światowy lider branży, który dostarcza sprawdzone i niezawodne rozwiązania dla Twojego biznesu. Narzędzia Kennametal i Stellram charakteryzuje wysoka precyzja, długa żywotność i szybkie działanie. Nadzwyczajna wydajność i renoma producenta to gwarancja sukcesu dla Twojej firmy.",
      ogImage: KennaLogo,
    },
    hero: {
      title: "Wysoki standard działania",
      subtitle:
        "Światowy lider branży, który dostarcza sprawdzone i niezawodne rozwiązania dla Twojego biznesu. Narzędzia Kennametal i Stellram charakteryzuje wysoka precyzja, długa żywotność i szybkie działanie. Nadzwyczajna wydajność i renoma producenta to gwarancja sukcesu dla Twojej firmy.",
      ogImage: KennaLogo,
    },
    rotary: {
      title: "Narzędzia obrotowe",
      subtitle:
        "Frezy i wiertła węglikowe pozwalające na szybką i precyzyjną obróbkę metalu. Zwiększona trwałość narzędzi, lepsza jakość wykończenia obrabianej powierzchni i doskonałe dopasowanie do rodzaju materiału - to charakteryzuje narzędzia obrotowe Kennametal.",
      text:
        "W katalogu m.in.: GOdrill, Beyond, Kenna Universal, DUO-LOCK, KenTip, KenTip FS, KSEM, KSEM PLUS, Harvi, GOmill, RSM.",
      src: photos.img1,
      bigImg: true,
      sellInfo: {
        number: 23212,
        caption: (
          <>
            Sprzedanych
            <br/>
            produktów
          </>
        ),
        icon: RotarySell,
      },
      links: [
        {
          text: "Frezowanie czołowe",
          linkedCatalogues: [
            {
              title:
                "DODEKA lider w dziedzinie zaawansowanego frezowania płaszczyzn",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Frezowanie-czolowe/DODEKA-lider-w-dzidzinie-zaawansowanego-frezowania-plaszczyzn.pdf",
            },
            {
              title: "Mill 16 frezowanie czołowe żeliwa",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Frezowanie-czolowe/Mill-16-frezowanie-czolowe-zeliwa.pdf",
            },
            {
              title: "KSSM 45 do obróbki konwencjonalnej",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Frezowanie-czolowe/KSSM-45-do-obrobki-konwencjonalnej.pdf",
            },
            {
              title:
                "KSOM i KSOM Mini najlepsze w swojej klasie frezy czołowe do obróbki skrawaniem",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Frezowanie-czolowe/KSOM-i-KSOM-Mini-najlepsze-w-swojej-klasie-frezy-czolowe-do-obrobki-skrawaniem-w-automatach.pdf",
            },
            {
              title: "KSSM8+ Dwustronne frezy czołowe z 8 krawędziami",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Frezowanie-czolowe/KSSM8+Dwustronne-frezy-czolowe-z-8-krawedziami-i-katem-przystawienia-zblizonym-do-90-stopni.pdf",
            },
            {
              title:
                "Fix-Perfect frezy do obróbki zgrubnej i wykańczającej żeliwa",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Frezowanie-czolowe/Fix-Perfect-frezy-do-obrobki-zgrubnej-i-wykanczjacej-zeliwa.pdf",
            },
            {
              title: "HexaCut obróbka komponentów do branży motoryzacyjnej",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Frezowanie-czolowe/HexaCut-obrobka-komponetow-do-branzy-motoryzacyjnej.pdf",
            },
            {
              title: "KSSR Frezy do obróbki zgrubnej i wykańczającej żeliwa",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Frezowanie-czolowe/KSSR-Frezy-do-obrobki-zgrubnej-i-wykanczajacej-zeliwa-z-katem-przylozenia-84-stopnie.pdf",
            },
            {
              title: "KCFM 45 frezowanie wykańczające żeliwa",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Frezowanie-czolowe/KCFM-45-frezowanie-wykanczajace-zeliwa.pdf",
            },
            {
              title: "MEGA 45 do prawdziwie ciężkiej obróbki",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Frezowanie-czolowe/MEGA-45-do-prawdziwie-ciezkiej-obrobki.pdf",
            },
            {
              title: "MEGA 60 i 90 do prawdziwie ciężkiej obróbki",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Frezowanie-czolowe/MEGA-60-i-90-do-prawdziwie-ciezkiej-obrobki.pdf",
            },
            {
              title: "KBDM Frezy czołowe PKD",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Frezowanie-czolowe/KBDM-Frezy-czolowe-PKD.pdf",
            },
            {
              title: "KSCM AluMill i KCSM PCD AluMill",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Frezowanie-czolowe/KSCM-AluMill-i-KCSM-PCD-AluMill.pdf",
            },
            {
              title: "Fix-Perfect do obróbki aluminium",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Frezowanie-czolowe/Fix-Perfect-do-obrobki-aluminium.pdf",
            },
          ],
        },
        {
          text: "Frezowanie kształtowe",
          linkedCatalogues: [
            {
              title:
                "Stellram 7792VX seria do stosowania przy dużych wartościach posuwu",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Frezowanie-ksztaltowe/Stellram-7792VX-seria-do-stosowania-przy-duzych-wartosciach-posuwu.pdf",
            },
            {
              title:
                "KenFeed 2X dwustronne frezy do stosowania przy dużych wartościach posuwu",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Frezowanie-ksztaltowe/KenFeed-2X-dwustronne-frezy-do-stosowania-przy-duzych-wartosciach-posuwu.pdf",
            },
            {
              title: "Rodeka dwustronna płytka okrągła",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Frezowanie-ksztaltowe/Rodeka-dwustronna-plytka-okragla.pdf",
            },
            {
              title:
                "KSRM płytki okrągłe opracowane specjalnie do obróbki tytanu i stali nierdzewnej",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Frezowanie-ksztaltowe/KSRM-plytki-okragle-opracowane-specjalnie-do-obrobki-tytanu-i-stali-nierdzewnej.pdf",
            },
            {
              title: "Stellram 7713VR płytki okrągłe",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Frezowanie-ksztaltowe/Stellram-7713VR-plytki-okragle.pdf",
            },
            {
              title: "Stellram 5505VX",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Frezowanie-ksztaltowe/Stellram-5505VX.pdf",
            },
            {
              title: "KDMB, KDMT, KDMS",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Frezowanie-ksztaltowe/KDMB-KDMT-KDMS.pdf",
            },
            {
              title: "KIPR",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Frezowanie-ksztaltowe/KIPR.pdf",
            },
          ],
        },
        {
          text: "Frezowanie rowków",
          linkedCatalogues: [
            {
              title: "KTMS platforma do rowków typu T",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Frezowanie-rowkow/KTMS-platforma-do-rowkow-typu-T.pdf",
            },
            {
              title: "KVNS platforma do frezowania bardzo wąskich rowków",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Frezowanie-rowkow/KVNS-platforma-do-frezowania-bardzo-waskich-rowkow.pdf",
            },
            {
              title: "SN popularne frezy z kwadratową płytką",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Frezowanie-rowkow/SN-popularne-frezy-z-kwadratowa-plytka.pdf",
            },
            {
              title: "LN system frezów o regulowanej szerokości",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Frezowanie-rowkow/LN-system-frezow-o-regulowanej-szerokosci.pdf",
            },
            {
              title: "KSSM10 i KSSM12",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Frezowanie-rowkow/KSSM10-i-KSSM12.pdf",
            },
          ],
        },
        {
          text: "Frezowanie walcowo-czołowe",
          linkedCatalogues: [
            {
              title: "Mill4 dwustronne frezowanie walcowo-czołowe",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Frezowanie-walcowo-czolowe/Mill4-dwustronne-frezowanie-walcowo-czolowe.pdf",
            },
            {
              title: "Mill 1 jednostronne frezowanie walcowo-czołowe",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Frezowanie-walcowo-czolowe/Mill-1-jednostronne-frezowanie-walcowo-czolowe.pdf",
            },
            {
              title: "Seria  Stellram 5720VZ16",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Frezowanie-walcowo-czolowe/Seria-Stellram-5720VZ16-skrawanie-aluminium-z-wysoka-predkoscia.pdf",
            },
            {
              title: "KSSM Frezowanie odsadzeń prostopadłych",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Frezowanie-walcowo-czolowe/KSSM-Frezowanie-odsadzen-prostopadlych.pdf",
            },
            {
              title: "KSSM-KSSP spiralne frezowanie odsadzeń prostopadłych",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Frezowanie-walcowo-czolowe/KSSM-KSSP-spiralne-frezowanie-odsadzen-prostopadlych.pdf",
            },
            {
              title: "Seria Stellram 5230VS",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Frezowanie-walcowo-czolowe/seria-Stellram-5230VS.pdf",
            },
          ],
        },
        {
          text: "Frezy do gwintów",
          linkedCatalogues: [
            {
              title: "Składane frezy do gwintów",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Frezy-do-gwintow/Skladane-frezy-do-gwintow.pdf",
            },
            {
              title: "Seria KTMD U",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Frezy-do-gwintow/Seria-KTMD-U.pdf",
            },
            {
              title: "TMS system frezowania gwintów",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Frezy-do-gwintow/TMS-system-frezowania-gwintow.pdf",
            },
          ],
        },
        {
          text: "Gwintowanie",
          linkedCatalogues: [
            {
              title: "Gwintowniki",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Gwintowanie/Gwintowniki.pdf",
            },
            {
              title: "Wyganitaki",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Gwintowanie/Wyganitaki.pdf",
            },
            {
              title: "Gwintowniki rurowe",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Gwintowanie/Gwintowniki-rurowe.pdf",
            },
          ],
        },
        {
          text: "Monolityczne frezy trzpieniowe",
          linkedCatalogues: [
            {
              title: "Wysokowydajne monolityczne frezy z węglika spiekanego",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Monolityczne-frezy-trzpieniowe/Wysokowydajne-monolityczne-frezy-z-weglika-spiekanego.pdf",
            },
            {
              title:
                "Monolityczne frezy z węglika spiekanego do obróbki gwintów",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Monolityczne-frezy-trzpieniowe/Monolityczne-frezy-z-weglika-spiekanego-do-obrobki-gwintow.pdf",
            },
            {
              title:
                "Monolityczne frezy trzpieniowe z węglika spiekanego do ogólnego przeznaczenia",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Monolityczne-frezy-trzpieniowe/Monolityczne-frezy-trzpieniowe-z-weglika-spiekanego-do-ogolnego-przeznaczenia.pdf",
            },
            {
              title: "Frezy modułowe Duo-Lock",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Monolityczne-frezy-trzpieniowe/Frezy-modulowe-Duo-Lock.pdf",
            },
          ],
        },
        {
          text: "Obróbka wykańczająca otworu",
          linkedCatalogues: [
            {
              title:
                "Monolityczne narzędzia RMS do rozwiercania z węglika spiekanego",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Obrobka-wykanczajaca-otworu/Monolityczne-narzedzia-RMS-do-rozwiercania-z-weglika-spiekanego.pdf",
            },
            {
              title:
                "Narzędzia RMR, RMB i RMB-E do rozwiercania z ostrzami lutowanymi",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Obrobka-wykanczajaca-otworu/Narzedzia-RMR-RMB-i-RMB-E-do-rozwiercania-z-ostrzami-lutowanymi.pdf",
            },
            {
              title: "Modułowe narzędzia RHR, RHM i RHM-E do rozwiercania",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Obrobka-wykanczajaca-otworu/Modulowe-narzedzia-RHR-RHM-i-RHM-E-do-rozwiercania.pdf",
            },
            {
              title: "Regulowana oprawka SIF",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Obrobka-wykanczajaca-otworu/Regulowana-oprawka-SIF.pdf",
            },
            {
              title:
                "Narzędzia RIR i RIQ do rozwiercania z elementem prowadzącym",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Obrobka-wykanczajaca-otworu/Narzedzia-RIR-i-RIQ-do-rozwiercania-z-elementem-prowadzacym.pdf",
            },
            {
              title:
                "System narzędzi wytaczarskich Romicron do obróbki wysokowydajnej",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Obrobka-wykanczajaca-otworu/System-narzedzi-wytaczarskich-Romicron-do-obrobki-wysokowydajnej.pdf",
            },
            {
              title: "System narzędzi wytaczarskich ModBORE",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Obrobka-wykanczajaca-otworu/System-narzedzi-wytaczarskich-ModBORE.pdf",
            },
            {
              title: "Niestandardowe narzędzia PCD",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Obrobka-wykanczajaca-otworu/Niestandardowe-narzedzia-PCD.pdf",
            },
          ],
        },
        {
          text: "Wiercenie kombinowane",
          linkedCatalogues: [
            {
              title: "System wiercenia kombinowanego BF",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Wiercenie-kombinowane/System-wiercenia-kombinowanego-BF.pdf",
            },
            {
              title: "System wiercenia kombinowanego SEFAS",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Wiercenie-kombinowane/System-wiercenia-kombinowanego-SEFAS.pdf",
            },
          ],
        },
        {
          text: "Wiertła modułowe",
          linkedCatalogues: [
            {
              title: "System wierteł modułowych KenTip FS",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Wiertla-modolowe/System-wiertel-modulowych-KenTip-FS.pdf",
            },
            {
              title: "System wierteł modułowych KenTip",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Wiertla-modolowe/System-wiertel-modulowych-KenTip.pdf",
            },
            {
              title: "System wierteł modułowych KSEM",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Wiertla-modolowe/System-wiertel-modulowych-KSEM.pdf",
            },
            {
              title: "System wierteł modułowych KSEM PLUS",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Wiertla-modolowe/System-wiertel-modulowych-KSEM-PLUS.pdf",
            },
          ],
        },
        {
          text: "Wiertła monolityczne z węglika spiekanego",
          linkedCatalogues: [
            {
              title: "Wiertła GoDrill Wszechstronność materiałów",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Wiertla-monolityczne-z-weglika-spiekanego/Wiertla-GoDrill-Wszechstronnosc-materialow.pdf",
            },
            {
              title: "Wiertła TF wysoka wydajność obróbki",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Wiertla-monolityczne-z-weglika-spiekanego/Wiertla-TF-wysoka-wydajnosc-obrobki.pdf",
            },
            {
              title: "Wiertła SGL Beyond do stali nierdzewnej",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Wiertla-monolityczne-z-weglika-spiekanego/Wiertla-SGL-Beyond-do-stali-nierdzewnej.pdf",
            },
            {
              title: "Wiertła HP Beyond do stali",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Wiertla-monolityczne-z-weglika-spiekanego/Wiertla-HP-Beyond-do-stali.pdf",
            },
            {
              title: "Wiertła YPC Beyond do obróbki materiałów z żeliwa",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Wiertla-monolityczne-z-weglika-spiekanego/Wiertla-YPC-Beyond-do-obrobki-materialow-z-zeliwa.pdf",
            },
            {
              title: "Wiertła SE 4 ŁYSINKI do obróbki stali",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Wiertla-monolityczne-z-weglika-spiekanego/Wiertla-SE-4-LYSINKI-do-obrobki-stali.pdf",
            },
            {
              title: "Długie wiertła HP Beyond z 4 Łysinkami",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Wiertla-monolityczne-z-weglika-spiekanego/Dlugie-wiertla-HP-Beyond-z-4-lysinkami.pdf",
            },
            {
              title: "Wiertła HP Beyond do głębokich otworów",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Wiertla-monolityczne-z-weglika-spiekanego/Wiertla-HP-Beyond-do-glebokich-otworow.pdf",
            },
            {
              title: "Wiertła HPS Beyond do obróbki aluminium",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Wiertla-monolityczne-z-weglika-spiekanego/Wiertla-HPS-Beyond-do-obrobki-aluminium.pdf",
            },
            {
              title: "Wiertła Y-TECH obróbka materiałów trudno skrawalnych",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Wiertla-monolityczne-z-weglika-spiekanego/Wiertla-Y-TECH-obrobka-materialow-trudno-skrawalnych.pdf",
            },
            {
              title: "Wiertła TX do obróbki otworów precyzyjnych",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Wiertla-monolityczne-z-weglika-spiekanego/Wiertla-TX-do-obrobki-otworow-precyzyjnych.pdf",
            },
            {
              title:
                "Wiertła SPF do materiałów kompozytowych (CFRP) i materiłów wielowarstwowych",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Wiertla-monolityczne-z-weglika-spiekanego/Wiertla-SPF-do-materialow-kompozytowych-(CFRP)-i-materialow-wielowarstwowych.pdf",
            },
            {
              title: "Wiertła FB do otworów z płaskim dnem",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Wiertla-monolityczne-z-weglika-spiekanego/Wiertla-FB-do-otworow-z-plaskim-dnem.pdf",
            },
            {
              title: "Wiertła stopniowe HP Beyond",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Wiertla-monolityczne-z-weglika-spiekanego/Wiertla-stopniowe-HP-Beyond.pdf",
            },
            {
              title: "Wiertła KMH do materiałów hartowanych",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Wiertla-monolityczne-z-weglika-spiekanego/Wiertla-KMH-do-materialow-hartowanych.pdf",
            },
            {
              title: "Wiertła Kenna Uniwersal",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Wiertla-monolityczne-z-weglika-spiekanego/Wiertla-Kenna-Uniwersal.pdf",
            },
            {
              title: "Wiertła do nawiercania NC",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Wiertla-monolityczne-z-weglika-spiekanego/Wiertla-do-nawiercania-NC.pdf",
            },
          ],
        },
        {
          text: "Wiertła składane",
          linkedCatalogues: [
            {
              title: "Drill Fix, DFR, DFSP, DFT",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Wiertla-skladane/Drill-Fix-DFR-DFSP-DFT.pdf",
            },
            {
              title:
                "System wierteł składanych serii HTS do wiercenia głębokich otworów",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Wiertla-skladane/System-wiertel-skladanych-serii-HTS-do-wiercenia-glebokich-otworow.pdf",
            },
            {
              title: "Płytki do wierteł składanych",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Wiertla-skladane/Plytki-do-wiertel-skladanych.pdf",
            },
            {
              title: "Narzędzia CTR do wiercenia z pogłębianiem",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Wiertla-skladane/Narzedzia-CTR-do-wiercenia-z-poglebianiem.pdf",
            },
          ],
        },
      ],
    },
    turning: {
      title: "Narzędzia do toczenia",
      subtitle:
        "Narzędzia do toczenia Kennametal zapewniają doskonałe odprowadzanie wiórów i redukują przestój maszyn. Zapewniona przez nie wysoka wydajność niesie ze sobą wysoką produktywność, a do tego gwarantuje brak odkształceń obrabianego przedmiotu",
      text:
        "W katalogu m.in.: Płytki tokarskie - obróbka rowków, toczenie i przecinanie, Płytki K-lock, Kenlock, system Fix Perfect, wytaczaki i adaptery do wytaczaków, Beyond Evolution, Narzędzia A4, Top Notch.",
      src: photos.img2,
      bigImg: true,
      links: [
        {
          text: "Obróbka rowków i przycinanie",
          linkedCatalogues: [
            {
              title: "Obróbka płytkich rowków",
              url:
                "/catalogs/kennametal/narzedzia-do-toczenia/obrobka-rowkow-i-przecinanie/Top-Notch-obrobka-plytkich-rowkow.pdf",
            },
            {
              title: "Obróbka rowków i przycinanie",
              url:
                "/catalogs/kennametal/narzedzia-do-toczenia/obrobka-rowkow-i-przecinanie/obrobka-rowkow-i-toczenie-ogolne-A4.pdf",
            },
            {
              title: "Obróbka rowków toczenie i przecinanie",
              url:
                "/catalogs/kennametal/narzedzia-do-toczenia/obrobka-rowkow-i-przecinanie/Beyond-Evolution-obrobka-rowkow-toczenie-i-przecinanie.pdf",
            },
          ],
        },
        {
          text: "Specjalne zastosowanie",
          linkedCatalogues: [
            {
              title: "Toczenie zespołów kołowych",
              url:
                "/catalogs/kennametal/narzedzia-do-toczenia/specjalne-zastosowanie/toczenie-zespolow-kolowych.pdf",
            },
            {
              title: "Płytki K-Lock",
              url:
                "/catalogs/kennametal/narzedzia-do-toczenia/specjalne-zastosowanie/plytki-k-lock.pdf",
            },
            {
              title: "Obróbka kształtowa",
              url:
                "/catalogs/kennametal/narzedzia-do-toczenia/specjalne-zastosowanie/obrobka-ksztaltowa-Top-Notch.pdf",
            },
            {
              title: "Fix Perfect",
              url:
                "/catalogs/kennametal/narzedzia-do-toczenia/specjalne-zastosowanie/Fix-Perfect.pdf",
            },
          ],
        },
        {
          text: "Toczenie gwintów",
          linkedCatalogues: [
            {
              title: "Toczenie gwintów Top Notch",
              url:
                "/catalogs/kennametal/narzedzia-do-toczenia/toczenie-gwintow/toczenie-gwintow-top-notch.pdf",
            },
            {
              title: "Toczenie gwintów narzędziami Laydown",
              url:
                "/catalogs/kennametal/narzedzia-do-toczenia/toczenie-gwintow/toczenie-gwontow-narzedziami-laydown.pdf",
            },
          ],
        },
        {
          text: "Toczenie ISO",
          linkedCatalogues: [
            {
              title: "Wytaczaki z nastawnym tłumikiem drgań",
              url:
                "/catalogs/kennametal/narzedzia-do-toczenia/toczenie-iso/wytaczaki-z-nastawnym-tlumikiem-drgan.pdf",
            },
            {
              title: "System doboru płytek Kennametal",
              url:
                "/catalogs/kennametal/narzedzia-do-toczenia/toczenie-iso/system-doboru-plytek-kennametal.pdf",
            },
            {
              title: "Płytki z materiałów zaawansowanych",
              url:
                "/catalogs/kennametal/narzedzia-do-toczenia/toczenie-iso/plytki-z-materialow-zaawansowanych-oprawki-wytaczaki-wkladki-i-gniazda-pod-glowice-przykrecane.pdf",
            },
            {
              title: "Płytki pozytywowe z chwytem gwintowanym",
              url:
                "/catalogs/kennametal/narzedzia-do-toczenia/toczenie-iso/plytki-pozytywowe-z-chwytem-gwintowanym-oprawki-wytaczaki-wkladki-i-gniazda-pod-glowice-przykrecane.pdf",
            },
            {
              title: "Płytki negatywowe Kenloc",
              url:
                "/demo/wumax/catalogs/kennametal/narzedzia-do-toczenia/toczenie-iso/plytki-negatywowe-Kenloc-oprawki-wytaczaki-wkladki-i-gniazda-pod-glowic%ea-przykrecane.pdf",
            },
            {
              title: "Płytki pozytywowe Kenloc",
              url:
                "/demo/wumax/catalogs/kennametal/narzedzia-do-toczenia/toczenie-iso/plytki-pozytywowe-Kenloc-oprawki-wytaczaki-wkladki-i-gniazda-pod-glowic%ea-przykrecane.pdf",
            },
          ],
        },
      ],
    },
    stellram: {
      title: "Stellram",
      subtitle:
        "Katalog zawiera najnowsze informacje o marce Stellram®: frezowanie wymienne, frezowanie pełnowęglikowe, toczenie i gwintowanie narzędzi - wszystko specjalnie zaprojektowane w celu zwiększenia produktywności, nawet w najtrudniejszych wyzwaniach w świecie obróbki metali.",
      links: [
        {
          text: "Zobacz katalog",
          url:
            "/catalogs/kennametal/stellram/KMT_Stellram_cutting_tools_metric.pdf",
        },
      ],
      src: photos.img3,
      bigImg: true,
    },
    toolsystem: {
      title: "Systemy narzędziowe",
      subtitle:
        "Rozwiązania, które umożliwiają pracę tam, gdzie dostęp do powierzchni obrabianej jest utrudniony, a także zwiększają zasięg narzędzia i skracają czas ustawiania maszyny. Systemy narzędziowe Kennametal to ekonomia czasu i oszczędności, a także spore ułatwienie w codziennej pracy.",
      text:
        "W katalogu m.in.: Oprawki KM, DOOSAN, HAAS, HWACHEON, HYUNDAI WIA, Mazak, Mori Siki, OKUMA.",
      src: photos.img4,
      bigImg: true,
      links: [
        {
          text: "Zobacz katalog",
          url:
            "/catalogs/kennametal/systemy-narzedziowe/systemy-narzedziowe.pdf",
        },
      ],
    },
    innovations: {
      title: "Innowacje Kennametal",
      subtitle:
        "Innowacyjne i opatentowane rozwiązania firmy Kennametal wspomagają precyzyjną i stabilną obróbkę materiału. Nowe technologie, które umożliwiają bardziej ekonomiczną i wydajną pracę. Maksymalna produktywność to maksymalne korzyści dla Twojej firmy.",
      text:
        "W katalogu m.in.: Wiertło HPX, Wiercenie modułowe KenTIP FS, Wytaczanie modułowe eBore, Monolityczne frezy trzpieniowe HARVI I TE, Tulejki zaciskowe ER do Duo-Lock.",
      src: photos.img5,
      bigImg: true,
      links: [
        {
          text: "Innowacje 2021 (1)",
          url:
            "/catalogs/kennametal/innowacje/innowacje-2021-01.pdf",
        },
        {
          text: "Innowacje 2020 (1)",
          url:
            "/catalogs/kennametal/innowacje/innowacje-2020-00.pdf",
        },
        {
          text: "Innowacje 2020 (2)",
          url:
            "/catalogs/kennametal/innowacje/innowacje-2020-01.pdf",
        },
        {
          text: "Innowacje 2020 (3)",
          url:
            "/catalogs/kennametal/innowacje/innowacje-2020-02.pdf",
        },
      ],
    },
  };
};
