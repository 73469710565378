import { graphql, useStaticQuery } from "gatsby";

export const useKennaPhotos = () => {
  const query = useStaticQuery(graphql`
    query {
      img1: file(relativePath: { eq: "image/kennametal/kennametal-1.png" }) {
        childImageSharp {
          fluid(maxHeight: 600, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      img2: file(relativePath: { eq: "image/kennametal/kennametal-4.png" }) {
        childImageSharp {
          fluid(maxHeight: 600, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      img3: file(relativePath: { eq: "image/kennametal/stellram-logo.png" }) {
        childImageSharp {
          fluid(maxHeight: 600, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      img4: file(relativePath: { eq: "image/kennametal/kennametal-3.png" }) {
        childImageSharp {
          fluid(maxHeight: 600, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      img5: file(relativePath: { eq: "image/kennametal/kennametal-2.png" }) {
        childImageSharp {
          fluid(maxHeight: 600, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);
  return query;
};
